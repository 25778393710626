import React from 'react';
import Layout from '../../components/Shared/Layout';
import Coinbase from '../../components/Portfolio/coinbase';
import '../../assets/css/bbqguru.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class coinbase extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/cryptocurrency-exchange',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Coinbase />
      </Layout>
    );
  }
}

export default coinbase;
